<template>
  <section class="section p-5">
    <div>
      <app-logo class="my-5"/>
      <div class="mb-3">
        <h5 class="title is-5 pt-5 mb-1">{{$t('account.createUser')}}</h5>
        <p>{{$t('account.selectAvatarAndName')}}</p>
      </div>
      <div class="fill-strech hmt-5">

        <!-- avatar  -->
        <b-field class="avatarSelector mb-3">
          <b-radio v-model="avatar" name="avatar" v-for="(av, i) in $store.state.avatars" :key="i" :native-value="i">
            <div class="playerAvatar" :class="{ selected: i == avatar }" :style="{ backgroundColor: `#${color}` }">
              <img :src="av.image.sizes.thumbnail.url" />
            </div>
          </b-radio>
        </b-field>

        <!-- color -->
        <b-field class="mb-5">
          <input type="range" min="0" max="360" step="1" value="50" class="slider" id="hueSlider" v-model="hueVal">
        </b-field>

        <!-- username -->
        <b-field>
          <b-input
            v-model="username"
            :placeholder="$t('account.usernamePlaceholder')"
            maxlength="25"
            expanded
            :autofocus="true"
            :class="{'border-radius': !randUsername}"/>
          <p class="control">
            <b-button v-if="randUsername" type="is-primary" @click="Randomize()">
              <d-icon pack="fas" icon="shuffle" size="is-small"/>
            </b-button>
          </p>
        </b-field>

        <b-button
          :label="playButtonLabel"
          type="is-primary"
          expanded
          :disabled="ValidateUsername()"
          @click="Register()" />
        </div>
      <div class="m-3 has-text-centered">
        {{$t('account.alreadyHaveAccount')}}
        <a @click="GoToLogin()" class="goLink has-text-black-bis has-text-weight-bold">
          {{$t('account.login')}}</a>
      </div>
    </div>
  </section>
</template>

<script>
const whitelabelConfig = require('@/whitelabel.config')
import { hsv2hex } from "color-functions"

const ACCOUNTSTATE = Object.freeze({
  inactive: 0,
  login: 1,
  register: 2,
  verify: 3,
});

export default {
  props: {},
  data() {
    return {
      randUsername: false, 
      username: "",
      fakeUsername: "",
      acceptTerms: false,
      avatar: 0,
      color: "fcfcf0",
      hueVal: 100,
    };
  },
  computed: {
    ShowMe() {
      return this.$store.state.user.accountState == ACCOUNTSTATE.register;
    },
    createdUsername() {
      return this.username.length > 3;
    },
    playButtonLabel() {
      return this.createdUsername ? this.$t('account.buttonPlayAsUsername', [this.username]) : this.$t('account.buttonPlayGuest');
    },
  },
  methods: {
    clearIconClick(){
      this.username = ""
    },
    ValidateUsername(){
      return this.username == '' || this.username.length < 2 || this.username.indexOf('@') != -1
    },
    Register() {
      this.$store.dispatch("user/CreateGuestUser", { username: this.username, color: this.color, avatar: this.avatar });
    },
    GoToLogin() {
      // this.$store.commit("user/SetAccountState", 1); // login
      this.$router.push("/login")
    },
    Randomize() {
      if (this.randUsername) this.username = whitelabelConfig.randomNickname()
      
      this.avatar = Math.floor(Math.random() * (this.$store.state.avatars.length - 2) + 1)

      this.hueVal = Math.floor(Math.random() * 360)

      this.color = hsv2hex(
        this.hueVal,
        this.$store.getters["user/ColorSaturation"],
        this.$store.getters["user/ColorBrightness"]
      ).substring(1);
    }
  },
  watch: {
    hueVal(newVal) {
      newVal = parseInt(newVal)
      this.color = hsv2hex(
        newVal,
        this.$store.getters["user/ColorSaturation"],
        this.$store.getters["user/ColorBrightness"]
      ).substring(1);
    },
  },
  beforeMount(){
    this.randUsername = !this.$store.state.setup.registration.dontSugestRandomUsername
  },
  mounted() {
    this.Randomize()
  }
};
</script>
<style lang="scss" scoped>
.section > div {
  display: flex;
  flex-flow: column;
  align-items: stretch;
}

.fill-strech {
  flex: 1 1 auto;
}

.goLink { 
  display: inline-block;
}
</style>